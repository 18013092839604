const destinationsOptions = [
  "Kenya",
  "South Africa",
  "Tanzania",
  "Morocco",
  "Egypt",
  "Namibia",
  "Zanzibar",
  "Senegal",
  "Ivory Coast",
  "Ghana",
  "Tunisia",
  "Madagascar",
];

export default destinationsOptions;
